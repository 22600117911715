<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            Lordtech
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Welcome to LordTech! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to your account and start the adventure
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="validationForm"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{name:'forget-password'}">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid || load"
            
            >

               <b-spinner v-if="load"
          small
          type="grow"
        />  
              Sign in
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link :to="{name:'register'}">
            <span>Create an account</span>
          </b-link>
        </b-card-text>

   
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BSpinner, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'

import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
     BSpinner,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      load : false,
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  methods: {
    async login() {
      this.load = true;

     var data = await this.$store.dispatch('login', {email: this.userEmail,password: this.password});  
  
if (data.status == true) {
  this.load = false;
    this.$router.push({name: 'dashboard'});
  this.$swal({
        title: 'Welcome To Your Dashboard',
       
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    
}else {

    console.log( Object.prototype.toString.call(data.message))
  if (data.object == true) {
       var data = JSON.parse(data.message)
       console.log(data);
    this.load = false;
    for (var k in data) {
        if (data.hasOwnProperty(k)) {

            this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data[k][0],
                    },
                  })
          //  user[k] = data[k];
        }
    }
    




  }else {

this.load = false;

       this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data.message,
                    },
                  })

  }
}






    //    try {
    //      await useJwt.login({
    //     email: this.userEmail,
    //     password: this.password,
    //   }).then(response => {
    //     console.log(response.data)
    //  if (response.data.status == true) {
    //      const { userData } = response.data
       
    //  }else {
    //     //  this.$refs.loginForm.setErrors(response.data.message)
                


    //  }
    //   });

    //   this.load = false;
    //    } catch (error) {
    //      let message = error.response.data.data.message;
    //       //  this.$refs.loginForm.setErrors(error.response.data.error)
    //       this.load = false;
    //         this.$toast({
    //                 component: ToastificationContent,
    //                 position: 'top-right',
    //                 props: {
    //                   title: `Error`,
    //                   icon: 'CoffeeIcon',
    //                   variant: 'danger',
    //                   text:  error.response.data.data.message != undefined ? error.response.data.data.message: "Something Went Wrong, Please Try again",
    //                 },
    //               })
         
    //    }
       
   }

   ,


    async validationForm() {
      this.$refs.loginForm.validate().then(success  => {
        if (success) {

          this.login();




          
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
